import React, { Component } from "react";
import { Table } from "react-bootstrap";
import UserService from "../services/user.service";
import PaperspaceService from "../services/paperspace.service";

export default class BoardUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      machines: []
    };
  }

  componentDidMount() {
    UserService.getAdminBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
    PaperspaceService.getNodes().then(
      response => {
        this.setState({
          machines: response.data
        })
      }
    )
  }

  render() {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>Admin</h3>
        </header>
        <ul>

        </ul>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>IP Address</th>
              <th>Region</th>
              <th>Storage Used</th>
              <th>Billing</th>
            </tr>
          </thead>
          <tbody>
          {(this.state.machines || []).map(machine => (
            <tr key={machine.id}>
              <td>{machine.id}</td>
              <td>{machine.name}</td>
              <td>{machine.publicIpAddress}</td>
              <td>{machine.region}</td>
              <td>{machine.storageUsed}</td>
              <td>{machine.usageRate}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      </div>
    );
  }
}