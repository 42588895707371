import React, { Component } from "react";
import { ListGroup, Button, ButtonGroup, Container, Row, Col } from "react-bootstrap";
import UserService from "../services/user.service";
import PaperspaceService from "../services/paperspace.service";
import OBSWebsocket from 'obs-websocket-js';

export default class BoardUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      machine: [],
      billingInfo: [],
      scenes: [],
      activeScene: "",
      heartbeatData: []
    };
    this.hoursUsed = 0;
    this.monthlyCost = 0;
    this.userInfo = JSON.parse(localStorage.getItem("user"));
    this.buttonText = "Boot";
    this.obs = new OBSWebsocket();
  }

  componentDidMount() {
    UserService.getUserBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    PaperspaceService.getNodeDetails(this.userInfo.server).then(
      response => {
        this.setState({
          machine: response.data
        });

        this.obs.connect({address: 'obscloud.net/websocket/' + this.userInfo.username, password: this.userInfo.obsWsPassword, secure: (window.location.protocol === 'https:') })
        .then(() => {
          return this.obs.call('GetSceneList');
        })
        .then(data => {
          let list = [];
          this.setState({activeScene: data.currentScene})
          data.scenes.forEach(scene => {
            list.push({
              key: scene.name
            });
          });
          this.setState({scenes: list});
          this.obs.call('SetHeartbeat', {
            enable: true
          })
          this.getSceneScreenshot();
        })
      }
    )

    PaperspaceService.getBillingDetails(this.userInfo.server).then(
      response => {
        this.setState({
          billingInfo: response.data
        });
        this.hoursUsed = Math.round( (this.state.billingInfo.utilization.secondsUsed/3600) * 100 + Number.EPSILON ) / 100
        this.monthlyCost = Math.round( (this.hoursUsed * this.state.billingInfo.utilization.hourlyRate + 5) * 100 + Number.EPSILON ) / 100
      }
    )

    this.obs.on('Heartbeat', data => {
      this.setState({heartbeatData: data});
    });

    this.handleServerState();
  }

  handleServerState(){
    switch(this.state.machine.state) {
      case 'off':
        return "Off"
      case 'starting':
        return 'Booting'
      case 'stopping':
        return 'Shutting down'
      case 'ready':
        return 'Ready'
      case 'serviceready':
        return'Booting'
      default:
        return 'Cannot determine power state'
    }
  }

  bootServer(){
    PaperspaceService.bootServer(this.state.machine.id).then(
      response => {
        this.handleServerState()
      }
    );
  }
  
  shutdownServer() {
    if (window.confirm('Are you sure you wish to shut down the server?')){
      PaperspaceService.stopServer(this.state.machine.id).then(
        response => {
          this.handleServerState()
        }
      )
    }
  }
  
  switchObsScenes(sceneName) {
    this.obs.call('SetCurrentScene', {
      'scene-name': sceneName
    }).then(
      this.obs.on('SwitchScenes', data => {
        this.setState({activeScene: data.sceneName})
        this.getSceneScreenshot();
      })
    );
  }

  startObsStreaming() {
    if (window.confirm('Are you sure you wish to start streaming?')){
      this.obs.call('StartStreaming')
    }
  }

  stopObsStreaming() {
    this.obs.call('StopStreaming')
  }

  getSceneScreenshot() {
    this.obs.call('TakeSourceScreenshot', { sourceName: this.state.activeScene, embedPictureFormat: 'png', width: 960, height: 540 }).then(
      data => {
        if(document.getElementById('program')){
          document.querySelector('#program').src = data.img;
        }
      }
    )
  }

  render() {
    return (
      <Container>
        <Row>
          <header className="jumbotron">
            <h3>My Server</h3>
          </header>
          <ListGroup variant="flush">
            <ListGroup.Item>IP Address: {this.state.machine.publicIpAddress}</ListGroup.Item>
            <ListGroup.Item>Region: {this.state.machine.region}</ListGroup.Item>
            <ListGroup.Item>Power Status: { this.handleServerState() } &nbsp;
              <Button 
                variant="primary"
                onClick={() => { this.state.machine.state === 'ready' ? this.shutdownServer() : this.bootServer() }} >
                  { this.state.machine.state === 'ready' ? 'Shut down' : 'Boot' }
              </Button>
            </ListGroup.Item>
            <ListGroup.Item>Hours used: { this.hoursUsed }</ListGroup.Item>
            <ListGroup.Item>Current monthly price: ${this.monthlyCost }</ListGroup.Item>
          </ListGroup>
        </Row>
        {this.state.machine.state === 'ready' ? (
          <div>
          <Row className='pt-2'>
          <Col>
            <Button 
              variant={this.state.heartbeatData.totalStreamTime == null ? 'success' : 'danger' }
              onClick={() => { this.state.heartbeatData.totalStreamTime == null ? this.startObsStreaming() : this.stopObsStreaming() }}>
                {this.state.heartbeatData.totalStreamTime == null ? 'Start Streaming' : 'Stop Streaming'}
            </Button>
            <p> {this.state.heartbeatData.stats == null ? null : Math.round(this.state.heartbeatData.stats.fps) + ' fps, '} {this.state.heartbeatData.stats == null ? null : Math.round(this.state.heartbeatData.stats['cpu-usage']) + '% CPU'} </p>
            <p> {this.state.heartbeatData.totalStreamTime == null ? null : 'Broadcast Time: ' + Math.round(this.state.heartbeatData.totalStreamTime) + ' seconds'} </p>
          </Col>
          </Row>
          <Row className='pt-2'>
            <ButtonGroup>
              { this.state.scenes.map(scene => (
                <Button key={scene.key} active={this.state.activeScene === scene.key} onClick={() => { this.switchObsScenes(scene.key) }}>{scene.key}</Button>
              ))}
            </ButtonGroup>
          </Row>
          <Row className='pt-3'>
            <img id="program" alt="Program"/> 
          </Row>
          </div>
        ) : ( <div></div>)}
      </Container>
    );
  }
}
