import React from 'react'
import { Redirect } from 'react-router-dom'

class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;

        var user = JSON.parse(localStorage.getItem("user"));

        const isAuthenticated = user['roles'][1] === "ROLE_ADMIN" || false;
       
        return isAuthenticated ? (
            <Component />
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
    }
}

export default ProtectedRoute;