import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Navbar,Nav,NavDropdown } from 'react-bootstrap';
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardAdmin from "./components/board-admin.component";
import ProtectedRoute from "./components/protected-route";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    const { currentUser, showAdminBoard } = this.state;

    return (
      <div>
        <Navbar bg="light" expand="lg" className="p-1">
          <Navbar.Brand href="/">
              OBS Cloud Server
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/home">Home</Nav.Link>
              {showAdminBoard && (
                <Nav.Link href="/admin">Admin</Nav.Link>
              )}
              {showAdminBoard && (
                <Nav.Link href="/register">Register</Nav.Link>
              )}
              {currentUser && (
                <Nav.Link href="/user">My Server</Nav.Link>
              )}
              {currentUser ? (
                <NavDropdown title={currentUser.username} id="basic-nav-dropdown" className="float-right">
                  <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                  <NavDropdown.Item href="/login" onClick={this.logOut}>Logout</NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link href="/login">Login</Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="container mt-3">
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/profile" component={Profile} />
            <Route path="/user" component={BoardUser} />
            <ProtectedRoute exact path="/register" component={Register} />
            <ProtectedRoute path="/admin" component={BoardAdmin} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;