import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://obscloud.net/api/paperspace/';

class PaperspaceService {
    getNodes(){
        return axios.get(API_URL + 'nodes', { headers: authHeader() });
    }

    getNodeDetails(machineId){
        return axios.get(API_URL + 'node_detail?machineId=' + machineId, { headers: authHeader() });
    }

    getBillingDetails(machineId){
        return axios.get(API_URL + 'billing_detail?machineId=' + machineId, {headers: authHeader() });
    }

    bootServer(machineId){
        return axios.get(API_URL + 'boot?machineId=' + machineId, {headers: authHeader() });
    }

    stopServer(machineId){
        return axios.get(API_URL + 'stop?machineId=' + machineId, {headers: authHeader() });
    }

    awaitState(machineId, state){
        return axios.get(API_URL + 'await?machineId=' + machineId + '&state=' + state, {headers: authHeader() });
    }
}

export default new PaperspaceService();